import {
    ModalDeleteDataType,
    ModalFilterDataType,
    ModalFilterModelType,
    SearchItem,
    ModalMergeDataType
} from "@/types/pages/Customers/Customers.type";
import {subjectTypeArray} from "@/config/subjectType";

export const MODAL_FILTER_MODEL = (): ModalFilterModelType => ({
    username: '',
    phone: '',
    source_id: '',
    product_id: [],
    language_id: '',
    is_company: '',
    created_at_from: '',
    created_at_to: '',
});

export const MODAL_FILTER_DATA = (): ModalFilterDataType => ({
    renderInputs: [
        {
            key: "phone",
            name: "common.form.phoneNumber",
            type: "input",
            items: null
        },
        {
            key: "date",
            name: "common.form.date",
            type: "double-date",
            items: null,
            keys: [
                "created_at_from",
                "created_at_to"
            ],
        },
        {
            key: "source_id",
            name: "common.table.sourceOfReference",
            type: "select",
            items: []
        },
        {
            key: "product_id",
            name: "common.table.referenceTheme",
            type: "tree",
            flattenItems: [],
            items: []
        },
        {
            key: "is_company",
            name: "common.subjectType",
            type: "select",
            items: subjectTypeArray
        },
        {
            key: "language_id",
            name: "common.table.languageOfAddress",
            type: "select",
            items: []
        },
    ],
    model: MODAL_FILTER_MODEL(),
    errorMessages: {},
    icon: "filter",
    name: "common.filter",
    "modal-window-class": "two-columns",
    visible: false,
});

export const MODAL_MERGE_DATA = (): ModalMergeDataType => ({
    icon: "",
    name: "customer.selectMainObject",
    "modal-window-class": "two-columns",
    model: {
        link_to: 0,
        customers: []
    },
    clearable: false,
    visible: false,
});

export const MODAL_DELETE_DATA = (): ModalDeleteDataType => ({
    visible: false,
    buttonCancelText: "common.no",
    buttonConfirmText: "common.yes",
    buttonConfirmTheme: "primary",
    modalWidth: "500px",
    customerId: null,
    isMultiple: false
});


export const SEARCH_ITEMS = [
    {
        name: 'common.fio',
        value: 'username',
        short: 'common.fio'
    },
    {
        short: "components.searchForm.phone",
        value: '1',
        placeholder: "--- --- -- --",
        mask: "##########"
    },
    {
        short: "components.searchForm.passport",
        value: '2',
        placeholder: "AZ --- -- --",
        mask: "AA #######"
    },
    {
        short: "components.searchForm.card",
        value: '3',
        placeholder: "---- ---- ---- ----",
        mask: "#### #### #### ####"
    },
    {
        short: "components.searchForm.internationalPassport",
        value: '4',
        placeholder: "components.searchForm.series",
        placeholderAdditional: "components.searchForm.number",
        mask: 'xx',
        maskAdditional: '########',
        double: true
    },
    {
        short: "components.searchForm.militaryID",
        value: '5',
        placeholder: "components.searchForm.series",
        placeholderAdditional: "components.searchForm.number",
        mask: 'xx',
        maskAdditional: '########',
        double: true
    },
];
