interface ICustomerPhone {
    phone: string;
    main: boolean;
    type: string;
}

interface ICustomerForm {
    code: null | number;
    username: string;
    email: string;
    birthday: string;
    gender: null | number;
    is_company: null | number;
    tin: null | number;
    pinfl: null | number;
    client_type: number;
    passport_no: string;
    passport_info: string;
    customer_phones: ICustomerPhone[];
}

const CUSTOMER_FORM = (): ICustomerForm => ({
    code: null,
    username: "",
    email: "",
    birthday: "",
    is_company: null,
    gender: null,
    tin: null,
    pinfl: null,
    client_type: 1,
    passport_no: "",
    passport_info: "",
    customer_phones: [
        {
            phone: "",
            main: false,
            type: "m"
        },
        {
            phone: "",
            main: false,
            type: "m"
        }
    ]
});

export { CUSTOMER_FORM };
