<template>
    <div class="customer-form">
        <create-form
            ref="create-card"
            v-model="form"
            :show-header="true"
            :company-enum="companyEnum"
            :client-gender="clientGender"
            :actions="['reset', 'cancel', 'submit']"
            @reset:form="toggleResetModal"
            @submit:form="submit"
        />

        <ConfirmModal
            v-if="modalDeleteData.visible"
            v-bind="modalDeleteData"
            v-on="modalDeleteListeners"
        >
            <template #title>{{ $t("errors.doYouWantToResetForm") }}</template>
        </ConfirmModal>
    </div>
</template>
<script>
import {get, post, put} from "@/helpers/api";
import {translationExists} from "@/mixins/local/translationExists.mixin";
import CreateForm from "@/components/admin/Customer/Create/Form.vue";
import {CUSTOMER_FORM} from "@/data/pages/Customers/CustomerForm";
import clientGender from "@/config/clientGender";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import {MODAL_DELETE_DATA} from "@/data/pages/Customers";
import {subjectTypeArray} from "@/config/subjectType";

export default {
    name: "CustomerForm",
    components: {
        ConfirmModal,
        CreateForm,
    },
    mixins: [translationExists],
    data() {
        return {
            is_loading: false,
            branch: '',
            types: [],
            regions: [],
            form: new CUSTOMER_FORM(),
            modalDeleteData: new MODAL_DELETE_DATA(),
        }
    },
    computed: {
        companyEnum() {
            return subjectTypeArray
        },
        clientGender() {
            return clientGender
        },
        modalDeleteListeners() {
            return {
                action: this.resetForm,
                "close-modal": this.toggleResetModal,
            }
        },
        birthdayWithTimezone() {
            let date = new Date(this.form.birthday);
            let timezoneOffsetMinutes = date.getTimezoneOffset();
            date = new Date(date.getTime() - timezoneOffsetMinutes * 60 * 1000);
            return date.toISOString();
        }
    },
    methods: {
        toggleResetModal() {
            this.modalDeleteData.visible = !this.modalDeleteData.visible;
        },
        resetForm() {
            this.form = new CUSTOMER_FORM();

            if (this.modalDeleteData.visible) {
                this.toggleResetModal();
            }
        },
        backToBranch() {
            this.$router.push('/customers')
        },
        setFormValuesFromRoute() {
            const {id} = this.$route.params;

            if (id) {
                this.loadCustomer(parseInt(id));
            }
        },
        async loadCustomer(id) {
            try {
                this.is_loading = true;

                const response = await get(`/customers/${id}`)
                if (response.data.success) {
                    this.form = response.data.data;
                }
            } catch (e) {
                this.$router.push('/pages/404')
            } finally {
                this.is_loading = false
            }
        },
        async submit() {

            this.$refs["create-card"].$v.$touch();
            if (this.$refs["create-card"].$v.$invalid) {
                return;
            }

            this.is_loading = true;

            const url = this.form.id ? `/customers/${this.form.id}` : '/customers';
            const method = this.form.id ? put : post;

            this.form.birthday = this.birthdayWithTimezone;

            try {
                const response = await method(url, this.form);

                if (response.data.success) {
                    this.$store.commit('notifications/error', {title: "requestsStatuses.success.index", text: "customer.customerSuccessCreated", type: "success"});
                    this.resetForm();
                    this.$router.push('/customers');
                }

            } catch (e) {
                this.$store.commit('notifications/error', {title: "errors.onCustomerCreate", text: "", type: "error"});
            } finally {
                this.is_loading = false;
            }
        }
    },
    created() {
        this.setFormValuesFromRoute()
    },
}
</script>

<style src="@/assets/scss/modules/pages/Customer/CustomerForm.scss" lang="scss"></style>
